body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ui.table tr td { border-top: 0px !important; }

.main-content { padding-top: 5em ; padding-bottom: 5em;}

.ui .scatter { margin-bottom: 8em; height: 60vh }

.ui .barg { margin-bottom: 8em; height: 70vh }

#header, #footer {
  background-image: linear-gradient(to left, #899adc, #6db1e8);
}


#header {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5) !important;
}
